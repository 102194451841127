import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.unshift.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.error.to-string.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import Nav from '@/components/Nav';
export default {
  data: function data() {
    return {
      swiperList: [require('@/assets/images/index1/swiper-1.png'), require('@/assets/images/index1/swiper-2.png'), require('@/assets/images/index1/swiper-3.png'), require('@/assets/images/index1/swiper-4.png'), require('@/assets/images/index1/swiper-5.png'), require('@/assets/images/index1/swiper-6.png'), require('@/assets/images/index1/swiper-7.png'), require('@/assets/images/index1/swiper-8.png')],
      swiperClass: [],
      timer: null,
      opacity: 0,
      innerHeight: 0,
      minHeight: null,
      paddingBottom: null,
      bottom: null
    };
  },
  components: {
    Nav: Nav
  },
  methods: {
    initSwiper: function initSwiper() {
      var _this = this;
      this.opacity = 0;
      var index = this.swiperList.length;
      var loop = function loop() {
        if (index > 0) {
          setTimeout(function () {
            _this.swiperClass.push(index);
            index--;
            loop();
          }, 200);
        }
      };
      loop();
      setTimeout(function () {
        _this.opacity = 1;
        _this.timer = setInterval(function () {
          var num = _this.swiperList.length - 1;
          var last = _this.swiperClass[num];
          _this.swiperClass.length = num;
          _this.swiperClass.unshift(last);
        }, 2500);
      }, 800);
    },
    getItemStyle: function getItemStyle(index) {
      var position = this.swiperClass.indexOf(index + 1);
      if (position === -1) return {
        bottom: '-500px'
      };
      if (position === 0) {
        return {
          bottom: '0px',
          zIndex: '20',
          opacity: '1',
          transform: 'translateZ(0px)'
        };
      }

      // 计算底部位置 - 从35px到90px均匀分布
      var totalItems = this.swiperList.length;
      var minBottom = 35;
      var maxBottom = 90;
      var step = totalItems > 2 ? (maxBottom - minBottom) / (totalItems - 2) : 0;
      var bottom = position === 1 ? minBottom : minBottom + step * (position - 1);

      // 计算透明度和缩放比例 - 逐渐减小
      var baseOpacity = 0.7;
      var opacityStep = 0.1;
      var opacity = Math.max(0.2, baseOpacity - (position - 1) * opacityStep);

      // 计算缩放比例 - 从0.94开始递减
      var baseScale = 0.94;
      var scaleStep = 0.02;
      var scale = Math.max(0.84, baseScale - (position - 1) * scaleStep);
      return {
        bottom: "".concat(bottom, "px"),
        zIndex: "".concat(20 - position),
        opacity: opacity.toString(),
        transform: "rotateX(-15deg) scale(".concat(scale, ") translateZ(-20px)")
      };
    }
  },
  mounted: function mounted() {
    this.initSwiper();
  },
  created: function created() {
    //原生浏览器底部状态栏可能会挡住页面
    var clientHeight = document.body.clientHeight || 0;
    var innerHeight = window.innerHeight || 0;
    var diff = clientHeight - innerHeight;
    //超过合理区间重置
    var height = diff < 100 && diff > -100 ? diff : 0;
    this.minHeight = "calc(100vh - ".concat(50 + height, "px)");
    this.paddingBottom = "".concat(50 + height, "px");
    this.bottom = "".concat(height + 17, "px");
  },
  beforeDestroy: function beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
  }
};